import { useStaticQuery, graphql } from "gatsby"

const aboutEng = graphql`
  {
    allDataYaml(filter: { codePage: { eq: "about-vi" } }) {
      edges {
        node {
          ...fragmentAboutPage
        }
      }
    }
  }
`
const usePageData = () => {
  const data = useStaticQuery(aboutEng)

  if (!data) return null

  return data.allDataYaml.edges[0].node
}

export default usePageData
