import React from "react"
import Layout from "components/layout"
import ViewAbout from "components/ViewAbout"
import usePageData from 'hooks/queryAbout.vi';

const AboutPage = props => {
  const { pageContext } = props;
  const pageData = usePageData()

  return (
    <Layout pageContext={pageContext}>
      <ViewAbout pageData={pageData} />
    </Layout>
  )
}

export default AboutPage
